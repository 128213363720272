.myLegend {
    bottom: 15px !important;
    width: 95%;
    opacity: 0.9;
    border-radius: 7px;
    box-shadow: 0 0 10px black;
    border: 1px darkgrey;
}
.nothasLegend {
    background: linear-gradient(to right,white, green 20% ,orange 40%, red 60%, blueviolet 80%, lightgray 100%);
}

.windGustLegend {
    background: linear-gradient(to right, purple,blueviolet, cyan, #7afcc8,green, rgb(182, 255, 142),yellow,orange, red,rgb(255, 0, 89));
}

.windSpeedLegend {
    background: linear-gradient(to right, purple,blueviolet,blue,rgb(0, 115, 255), rgb(115, 239, 255), #7afcc8,green, rgb(182, 255, 142),yellow,orange,rgb(153, 84, 0), red,rgb(255, 60, 0));
}

.cloudsLegend {
    background: linear-gradient(to right, white, white, white, white, white, white);
}

.radarLegend {
    background: linear-gradient(to right, cyan, rgb(0, 166, 255), blue, rgb(255, 249, 183), rgb(154, 213, 65), yellow, orange, darkorange, red, rgb(255, 0, 98),  blueviolet 100%);
}

.rainLegend {
    background: linear-gradient(to right, blueviolet,rgb(86, 43, 226),rgb(0, 115, 255), rgb(115, 239, 255), #7afcc8,green, rgb(182, 255, 142),yellow,orange,rgb(153, 84, 0), red,rgb(255, 60, 0));
}

.temperatureLegend {
    background: linear-gradient(to right, blueviolet,rgb(86, 43, 226),blue,rgb(0, 115, 255), rgb(115, 239, 255), #7afcc8,green,rgb(97, 211, 97), rgb(182, 255, 142),yellow,orange,rgb(153, 84, 0), red,rgb(255, 60, 0));
}

.visibilityLegend {
    background: linear-gradient(to right,rgb(255, 60, 0), red, rgb(153, 84, 0),orange,yellow,rgb(182, 255, 142),green, #7afcc8, rgb(68, 184, 157), rgb(0, 115, 255), rgb(87, 87, 87), blue);
}
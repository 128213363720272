.todayNewLoadCircle{

    color: #2A7821 !important;
    position: absolute;
    top: 50%;
    left: 50%;

}
.dailyContainerTodayNew{
    width: 100%;
    height: 100vh !important;
    font-size: 50px;
    font-family: Roboto sans-serif;
    color: rgba(51, 51, 51, 0.81) !important;
    text-shadow: black;
}

.innerContainerTodayNew{
    background-color: rgba(245, 245, 245, 0.5);
    box-shadow: 0 0 5px rgba(245, 245, 245, 0.5) !important;
    width: 100%;
    height: 90%;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;


}

.firstRowTodayNew{
    width: 100%;
    height: 10%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.municipalityNameTodayNew{

    font-size: 5vw;
    margin: 0;
    float: left;

}

.dateTodayNew{
    font-size: 4vw;
    margin-right: 0;
    float: right;

}

.secondRowTodayNew{
    width: 100%;
    height: 20%;
    padding: 20px;
}

.minmaxUpperNew{
    height: 15%;
    width: 100%;
    font-size: 4vw;
    /*background-color: #d49500;*/
    padding-left: 10px;
    padding-bottom: 25px;
}

.minmaxLowerNew{
    height: 90%;
    width: 100%;
    /*background-color: #e8e801;*/
    display: flex;
    text-shadow: 1px 1px #777777;

}

.minDivTodayNew{
 /*background-color: #bf0000;*/
    height: 100%;
    width: 25%;
    color: #2a7821 !important;
}

.minmaxBarDivTodayNew{
    /*background-color: #333333;*/
    height: 100%;
    width: 50%;

}

.minmaxBarTodayNew{
    /*background: linear-gradient(to right,#2a7821,#d49500);*/
    width: 85%;
    height: 35% !important;
    vertical-align: middle;
    position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50px;
    justify-content: space-between;
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 3vw;
    color: whitesmoke;
    align-items: center;
}

.textMiddleTodayNewMin{
    float: left;
    margin: 0;
    transform: translateY(-10%);
}
.textMiddleTodayNewMax{
    float: right;
    margin: 0;
    transform: translateY(-10%);
}

.maxDivTodayNew{
    /*background-color: #777777;*/
    height: 100%;
    width: 25%;
    color: #d49500 !important;
}

.minValueTodayNew{
    position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12vw;
}

.maxValueTodayNew{
    position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12vw;
}
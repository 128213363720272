.container{

    height: 70px;
    width: 450px;
    max-width: 100%;
    z-index: 4;
    opacity: 0.9;
    background: rgb(152,1,1);
    background: linear-gradient(202deg, rgba(152,1,1,1) 21%, rgba(167,0,0,1) 50%, rgba(152,1,1,1) 79%);
    position: absolute;
    bottom: 0;
    color: #F5F5F5;
    font-size: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 0 10px #333333;
    /*margin: auto;*/
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding:30px;
}

.errIcon{
    margin-right: 15px;
    transform: scale(1.8);
}
.selectPollutantLabel {
  font-size: medium;
  margin-right: 2px;
  color: black;
  font-weight: bold;
  background-color: darkcyan;
  width: 10rem;
  margin-bottom: 5px;
  padding-left: 5px;
  border-radius: 5%;
}

.selectPollutant {
  width: 100%;
  height: 30px;
  text-align: center;
  background-color: darkcyan;
  color: wheat;
  font-weight: 600;
  border: 2px solid #ccc;
  border-radius: 3%;
  font-size: medium;
}

.selectPollutantSwitch {
  width: 18rem;
}

.selectDomain { 
  width: 18rem;
  margin-top: 4rem;
}

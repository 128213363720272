table{
    font-size:11px;    
}

table tr:nth-child(5){
    border-bottom: 1px solid black;
}

table tr:nth-child(3) td:nth-child(1) {
    padding-left: 27px;
}

table tr:nth-child(4) td:nth-child(1) {
    text-align: right;
}

td.pollutant-td{
    padding:4px;
    min-width: 40px;
    font-size: 12pt;
}

.detailContainer{
    position: absolute;
    bottom:0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 410px;
    /*background-color: whitesmoke;*/
    background: #FFFFFF;  /* fallback for old browsers */
    /* background: -webkit-linear-gradient(to right, #FFFFFF, #FFFFFF);  Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(to right, #FFFFFF, #FFFFFF); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    opacity: 0.9;
    box-shadow: 0 0 2px #333333;
}

.hours {
    font-size:12px;
}

.legend{
    width: 6%;
    min-width: 110px;
    height: 100%;
    /*background-color: whitesmoke;*/
    float: left;
    color:#999;
    font-size:12px;
}

.div-table{
    height: 100%;
    white-space: nowrap;
}

.legendHoursDiv{
    height: 26px;
    text-align: center;
    line-height: 26px;
    margin-top: 25px;
    border-top-right-radius: 30px;
    /*box-shadow: 0 0 2px #999999;*/
    color: #bf0000 !important;

}

.timecode .MuiTooltip-tooltip::before {
    top: 100%;
    left: 2em;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    /*border-top-color: #d49500;*/
    border-width: .5em;
    margin-left: -0.5em;
}

.legendSpan{
    margin-right: 4px;
    vertical-align: middle;
    text-align: center;
    padding-right: 5px;
    color: #4b4b4b;
}

.legendItemBox{
    border-top-right-radius: 30px;
    box-shadow: 0 0 5px #c3c3c3;
    font-weight: bold;
    background-color: #f0f0f0;
}

.legendItem{
    height: 18em;
    text-align: center;
    line-height: 21px;
    margin: 0 !important;
    padding-top: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.parameterName {
    box-shadow: 0 0 5px #c3c3c3;
    font-weight: bold;
    background-color: #f0f0f0;
    text-align: center;
    font-size: 1.4em;
    margin: 0 !important;
    padding: 0 27px 0;
    align-items: center;
    justify-content: center;
}

.hours{
    font-size: 10pt;
    text-align: center;
    border-right: 1px solid lightblue;
}
.hours-end{
    font-size: 11pt;
    text-align: center;
    border-right: 1px solid gray;
    color: #bf0000;
}

td{
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
}

.spinner{
    height: 100%;
    line-height: 230px;
    text-align: center;
}
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

::-webkit-scrollbar {

    height: 16px !important;
    width: 16px !important;
    background: whitesmoke !important;
    left: -6px !important;
}

::-webkit-scrollbar-thumb {
    background: #777777 !important;
    -webkit-border-radius: 1ex !important;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75) !important;
}


.closeBtn{
    width: 40px;
    height: 40px;
    position: absolute !important;
    bottom: 390px !important;
    left: 0 !important;
    z-Index: 2 !important;
    margin-left: 15px !important;
    border-radius: 30px !important;
    color: whitesmoke;
    background-color: #bf0000 !important;
    opacity: 0.95 !important;
}

.detailsCloseX{
    font-size: 20px !important;
}

.closeBtn:hover{
    transform: scale(1.1) !important;
}
.mobileMapOuterContainer {
    /*margin-top: 4vh;*/
    border-top: 1px solid black;
    /*border-radius: 5px;*/
}

.logosSpan {
    z-index: 2;
    margin-top: 35px;
}

.markerInputDiv {
    width: 80vw;
    height: 13em;
    position: absolute;
    margin: 30vh 0 0 10vw;
    z-index: 3;
    background-color: #79b2fd;
    padding-top: 4vh;
    border-radius: 5px;
    box-shadow: 10px 7px 10px black;
    text-align: center;
}

.descriptionLabel {
    text-align: left;
    font-weight: 500;
}

.description {
    border-radius: 5px;
    border: 1px solid black;
    padding: 5px;
    width: 10em;
}

.submitLocationButton {
    background-color: #00ff98;
    padding: 5px;
    border: 1px solid black;
    border-radius: 6px;
    font-weight: 500;
    width: 5em;
}

.closeLocationInputButton{
    background-color: red;
    padding: 6px;
    margin-top: 2vh;
    margin-left: 10px;
    border: 1px solid black;
    border-radius: 5px;
    font-weight: 500;
    width: 5em;
}

.deleteLocationButton {
    margin-left: 20px;
    margin-top: 0;
    background-color: #fc2f00;
    padding: 0 1px;
    font-weight: 600;
    height: 17px;
    font-size: 11px;
    width: 50px;
}
.hourlyLoadCircle{

    color: #2A7821 !important;
    position: absolute;
    top: 50%;
    left: 50%;

}

.hourlyContainer{
    width: 100% !important;
    /*height: 100vh !important;*/
    font-size: 50px;
    font-family: Roboto sans-serif;
    color: rgba(51, 51, 51, 0.81) !important;
    text-shadow: black;
}

.hourlyDateDiv{
    width: 100%;
    height: 5vh;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 0 !important;
    padding-left: 10px;
    background-color: #ffffff;
    font-size: 4vw;
    color: #ebebeb;
    font-weight: bold;
    z-index: 2;
    /*box-shadow: 0 0 5px #8c8c8c;*/
    text-shadow: #999999;
    /*justify-content: center;*/
}

.hourlyDateTextStyle{
    /*transform: translate(4%,30%) !important;*/
    align-items: center;
    display: flex;
    color: #316183;
}
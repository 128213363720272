.hourlyContainerTemplete{
    width: 100% !important;
    height: 100vh !important;
    font-size: 50px;
    font-family: Roboto sans-serif;
    color: rgba(51, 51, 51, 0.81) !important;
    text-shadow: black;
}

.hourlyInnerContainerTemplete{
    background-color: rgba(245, 245, 245, 0.5);
    box-shadow: 0 0 5px rgba(245, 245, 245, 0.5) !important;
    width: 95%;
    height: 25%;
    margin: auto;
    position: relative !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

}

.hourlyFirstRowTemplete{
    width: 100%;
    height: 20%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;

    /*position: -webkit-sticky;*/
    /*position: -moz-sticky;*/
    /*position: -o-sticky;*/
    /*position: -ms-sticky;*/
    /*position: sticky;*/
    /*top: 0px;*/
    /*z-index: 10;*/
}

.hourlyMunicipalityNameTemplete{

    font-size: 5vw;
    margin: 0;
    float: left;
    color: #797979;
    font-weight: bold;

}

.hourlyDateTemplete{
    font-size: 4vw;
    margin-right: 0;
    float: right;
    text-align: center;
    color: #2a7821;
}

.hourlyDateAfterTemplete{
    font-size: 4vw;
    margin-right: 0;
    float: right;
    text-align: center;
    color: #2a7821;
}

.hourlySecondRowTemplete{
    width: 100%;
    height: 90%;
    display: flex;
    background-color: rgb(245, 245, 245);
    box-shadow: 0 0 5px rgba(245, 245, 245, 0.5) !important;
    padding: 8px;

}

.pm25DivTemplete{
    width: 25%;
    height: 100%;
    /*background-color: blueviolet;*/
}

.pmChartTemplete{
    height: 90%;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    /*background-color: darkred;*/
}

.pm10DivTemplete{
    width: 25%;
    height: 100%;
    /*background-color: #8722e2;*/
}

.noDivTemplete{
    width: 25%;
    height: 100%;
    /*background-color: #841ee2;*/
}

.so2DivTemplete{
    width: 25%;
    height: 100%;
    /*background-color: blueviolet;*/
}

.hourlyPollTypeDivTemplete{
    height: 10%;
    width: 100%;
    /*background-color: darkgreen;*/
    font-size: 6vw !important;
    text-align: center;
    top:-10px;
    position: relative;
    margin: 0 !important;

}

.hourlyPollutantChartValueDivTemplete{
    margin: 0;
    padding: 0;
    text-align: center;
    white-space: unset;
}

.hourlyPollutantChartParValTemplete{
    margin: 0;
    padding: 0;
    font-size: 6vw;
}

.hourlyPollutantChartParUnitTemplete{
    margin: 0;
    padding: 0;
    font-size: 2vw;
    color: #8c8c8c;
}

.CircularProgressbar-path {
    opacity: 0.7 !important;
}